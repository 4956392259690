.notFound {
	padding: 60px 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.notFound__heading {
	margin-bottom: 20px;
}