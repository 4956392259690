.crm {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.crm__login > span {
	margin-right: 0.5rem;
}

.crm__login > input[type="password"] {
	margin-right: 1rem;
}

.crm__content {
	margin-bottom: 3rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.crm__contentHero {
	margin-bottom: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.crm__contentHero > h2 {
	text-align: center;
}

.crm__contentHero > div:not(:last-child) {
	margin-bottom: 2rem;
}

.crm__contentSections {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.crm__contentSections:not(:last-child) {
	margin-bottom: 3rem;
}

.play__contentSectionsTitle {
	margin-bottom: 1.5rem;
	text-align: center;
}

.crm__contentSectionsContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: 1rem;
}

.crm__contentSection {
	border: 1px grey solid;
	border-radius: 0.5rem;
	padding: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 250px;
	height: 200px;
	background-color: wheat;
}

.crm__contentSection > h3 {
	text-align: center;
	margin-bottom: 1.5rem;
}