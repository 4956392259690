.footer {
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-top: 1px solid gainsboro;
}

.footer__title {
	margin-bottom: 0.5rem;
	text-align: center;
}

.footer__version {
	text-align: center;
	font-size: 0.8rem;
}