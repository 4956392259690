.english {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.english__content {
	margin-bottom: 3rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.english__contentHero {
	margin-bottom: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.english__contentHero > h2 {
	text-align: center;
}

.english__contentHero > div:not(:last-child) {
	margin-bottom: 2rem;
}

.english__contentSections {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.english__contentSections:not(:last-child) {
	margin-bottom: 3rem;
}

.english__contentSectionsTitle {
	margin-bottom: 1.5rem;
	text-align: center;
}

.english__contentSectionsContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: 1rem;
}

.english__contentSection {
	border: 1px grey solid;
	border-radius: 0.5rem;
	background-color: gold;
	padding: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 250px;
	height: 200px;
}

.english__contentSection > h3 {
	text-align: center;
	margin-bottom: 1.5rem;
}

.english__contentSection_inactive {
	background-color: rgba(0, 0, 0, 0.1);
}