h1 {
	margin-bottom: 1.5rem;
}

h2 {
	margin-bottom: 1rem;
}

h3,
h4,
h5,
h6 {
	margin-bottom: 0.5rem;
}