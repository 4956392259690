.math {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.math__content {
	margin-bottom: 3rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.math__contentHero {
	margin-bottom: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.math__contentHero > h2 {
	text-align: center;
}

.math__contentHero > div:not(:last-child) {
	margin-bottom: 2rem;
}

.math__contentSections {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.math__contentSections:not(:last-child) {
	margin-bottom: 3rem;
}

.math__contentSectionsTitle {
	margin-bottom: 1.5rem;
	text-align: center;
}

.math__contentSectionsContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: 1rem;
}

.math__contentSection {
	border: 1px grey solid;
	border-radius: 0.5rem;
	background-color: aquamarine;
	padding: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 250px;
	height: 200px;
}

.math__contentSection > h3 {
	text-align: center;
	margin-bottom: 1.5rem;
}

.math__contentSection_inactive {
	background-color: rgba(0, 0, 0, 0.1);
}