.multiplicationTable {
	margin-bottom: 2rem;
	padding: 2rem;
	background-color: aquamarine;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-items: flex-start;
	align-content: center;
	min-width: 332px;
}

.multiplicationTable > h3 {
	margin-bottom: 1rem;
	text-align: center;
}

.multiplicationTable div {
	text-align: center;
}

.multiplicationTable div:not(:last-child) {
	margin-bottom: 0.5rem;
}

.multiplicationTable__difficultyLevel > div:last-of-type {
	margin-bottom: 1rem;
}

.multiplicationTable button {
	border: 1px gainsboro solid;
	border-radius: 0.75rem;
	padding: 0.5rem 0.75rem;
	cursor: pointer;
}

.multiplicationTable__levelButton:not(:last-child) {
	margin-right: 0.5rem;
}

.multiplicationTable__levelButton.active {
	background-color: yellow;
}

.multiplicationTable__taskElements {
	margin-bottom: 0.75rem !important;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.multiplicationTable__multiple {
	margin: 0 !important;
	font-size: 2.5rem;
	font-weight: 700;
}

.multiplicationTable__multiplicationSign {
	margin: 0 1rem !important;
	font-size: 1.5rem;
	font-weight: 500;
}

.multiplicationTable__answer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.multiplicationTable__answerInput {
	margin-bottom: 0.75rem !important;
	padding: 0.25rem 0.5rem;
	width: 8rem;
	font-size: 2.25rem;
	font-weight: 700;
	text-align: right;
	color: #363636;
}

.larger {
	font-size: 1.5rem;
}

.bold {
	font-weight: 700;
}

.multiplicationTable__divider {
	margin: 1rem auto !important;
	width: 100%;
	height: 1px;
	font-size: 0;
	line-height: 0;
	background-color: grey;
}

.multiplicationTable__correctAnswers span,
.multiplicationTable__incorrectAnswers span {
	transition: background-color 0.5s linear;
}

.multiplicationTable__correctAnswers.active span {
	background-color: rgba(0, 128, 0, 0.5);
}

.multiplicationTable__incorrectAnswers.active span {
	background-color: rgba(255, 0, 0, 0.5);
}

.multiplicationTable__log {
	margin-top: 1rem;
	display: none;
	border: 1px grey solid;
	border-radius: 5px;
	padding: 5px;
}

.multiplicationTable__log div {
	text-align: left;
}

.multiplicationTable__log > h4 {
	padding-bottom: 0.25rem;
}

.multiplicationTable__log_visible {
	display: block;
}

.multiplicationTable__log h4 {
	border-bottom: 1px grey solid;
}

.multiplicationTable__eye {
	position: relative;
	margin-left: 10px;
}

.multiplicationTable__eye svg {
	position: absolute;
	top: -1px;
	width: 18px;
	height: 18px;
}

.multiplicationTable__answerStatistics {
	margin-bottom: 1rem !important;
}

.multiplicationTable__correctAnswersList {
	display: grid;
	grid-template-columns: 1fr 2fr;
}

.multiplicationTable__correctAnswerLogAnswer {
	padding: 0 0.25rem;
	background-color: rgba(0, 128, 0, 0.25);
}

.multiplicationTable__errorList {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
}

.multiplicationTable__errorLogAnswer {
	padding: 0 0.25rem;
	background-color: rgba(255, 0, 0, 0.25);
}