.objectPronouns {
	margin-bottom: 2rem;
	padding: 2rem;
	background-color: gold;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-items: flex-start;
	align-content: center;
	min-width: 332px;
}

.objectPronouns > h3 {
	margin-bottom: 1rem;
	text-align: center;
}

.objectPronouns div {
	text-align: center;
}

.objectPronouns div:not(:last-child) {
	margin-bottom: 0.5rem;
}

.objectPronouns__difficultyLevel > div:last-of-type {
	margin-bottom: 1rem;
}

.objectPronouns button {
	border: 1px gainsboro solid;
	border-radius: 0.75rem;
	padding: 0.5rem 0.75rem;
	cursor: pointer;
}

.objectPronouns__levelButton:not(:last-child) {
	margin-right: 0.5rem;
}

.objectPronouns__levelButton.active {
	background-color: yellow;
}

.objectPronouns__taskElements {
	margin-bottom: 0.75rem !important;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.objectPronouns__preposition {
	margin: 0 0.5rem 0 0 !important;
	font-size: 1.5rem;
	font-weight: 700;
}

.objectPronouns__pronoun {
	margin: 0 !important;
	font-size: 1.5rem;
	font-weight: 700;
}

.objectPronouns__answer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.objectPronouns__answerInput {
	margin-bottom: 0.75rem !important;
	padding: 0.25rem 0.5rem;
	width: 12rem;
	font-size: 1.5rem;
	font-weight: 700;
	text-align: right;
	color: #363636;
}

.larger {
	font-size: 1.5rem;
}

.bold {
	font-weight: 700;
}

.objectPronouns__divider {
	margin: 1rem auto !important;
	width: 100%;
	height: 1px;
	font-size: 0;
	line-height: 0;
	background-color: grey;
}

.objectPronouns__correctAnswers span,
.objectPronouns__incorrectAnswers span {
	transition: background-color 0.5s linear;
}

.objectPronouns__correctAnswers.active span {
	background-color: rgba(0, 128, 0, 0.5);
}

.objectPronouns__incorrectAnswers.active span {
	background-color: rgba(255, 0, 0, 0.5);
}

.objectPronouns__log {
	margin-top: 1rem;
	display: none;
	border: 1px grey solid;
	border-radius: 5px;
	padding: 5px;
}

.objectPronouns__log div {
	text-align: left;
}

.objectPronouns__log > h4 {
	padding-bottom: 0.25rem;
}

.objectPronouns__log_visible {
	display: block;
}

.objectPronouns__log h4 {
	border-bottom: 1px grey solid;
}

.objectPronouns__eye {
	position: relative;
	margin-left: 10px;
}

.objectPronouns__eye svg {
	position: absolute;
	top: -1px;
	width: 18px;
	height: 18px;
}

.objectPronouns__answerStatistics {
	margin-bottom: 1rem !important;
}

.objectPronouns__correctAnswersList {
	display: grid;
	grid-template-columns: 1.3fr 2.3fr;
}

.objectPronouns__correctAnswersList > div {
	margin-bottom: 0 !important;
}

.objectPronouns__correctAnswerLogQuestion {
	padding-right: 0.25rem;
}

.objectPronouns__correctAnswerLogAnswer {
	padding: 0 0.25rem;
	background-color: rgba(0, 128, 0, 0.25);
}

.objectPronouns__errorList {
	display: grid;
	grid-template-columns: 1.3fr 1fr 1.3fr;
}

.objectPronouns__errorList > div {
	margin-bottom: 0 !important;
}

.objectPronouns__errorLogQuestion {
	padding-right: 0.25rem;
}

.objectPronouns__errorLogAnswer {
	padding: 0 0.25rem;
	background-color: rgba(255, 0, 0, 0.25);
}

.objectPronouns__errorLogCorrectAnswer {
	padding-left: 0.25rem;
}