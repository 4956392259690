.additionInMind {
	margin-bottom: 2rem;
	padding: 2rem;
	background-color: aquamarine;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-items: flex-start;
	align-content: center;
	min-width: 332px;
}

.additionInMind > h3 {
	margin-bottom: 1rem;
	text-align: center;
}

.additionInMind div {
	text-align: center;
}

.additionInMind div:not(:last-child) {
	margin-bottom: 0.5rem;
}

.additionInMind__difficultyLevel > div:last-of-type {
	margin-bottom: 1rem;
}

.additionInMind button {
	border: 1px gainsboro solid;
	border-radius: 0.75rem;
	padding: 0.5rem 0.75rem;
	cursor: pointer;
}

.additionInMind__levelButton:not(:last-child) {
	margin-right: 0.5rem;
}

.additionInMind__levelButton.active {
	background-color: yellow;
}

.additionInMind__taskElements {
	margin-bottom: 0.75rem !important;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.additionInMind__term {
	margin: 0 !important;
	font-size: 2.5rem;
	font-weight: 700;
}

.additionInMind__additionSign {
	margin: 0 1rem !important;
	font-size: 1.5rem;
	font-weight: 500;
}

.additionInMind__answer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.additionInMind__answerInput {
	margin-bottom: 0.75rem !important;
	padding: 0.25rem 0.5rem;
	width: 8rem;
	font-size: 2.25rem;
	font-weight: 700;
	text-align: right;
	color: #363636;
}

.larger {
	font-size: 1.5rem;
}

.bold {
	font-weight: 700;
}

.additionInMind__divider {
	margin: 1rem auto !important;
	width: 100%;
	height: 1px;
	font-size: 0;
	line-height: 0;
	background-color: grey;
}

.additionInMind__correctAnswers span,
.additionInMind__incorrectAnswers span {
	transition: background-color 0.5s linear;
}

.additionInMind__correctAnswers.active span {
	background-color: rgba(0, 128, 0, 0.5);
}

.additionInMind__incorrectAnswers.active span {
	background-color: rgba(255, 0, 0, 0.5);
}

.additionInMind__log {
	margin-top: 1rem;
	display: none;
	border: 1px grey solid;
	border-radius: 5px;
	padding: 5px;
}

.additionInMind__log div {
	text-align: left;
}

.additionInMind__log > h4 {
	padding-bottom: 0.25rem;
}

.additionInMind__log_visible {
	display: block;
}

.additionInMind__log h4 {
	border-bottom: 1px grey solid;
}

.additionInMind__eye {
	position: relative;
	margin-left: 10px;
}

.additionInMind__eye svg {
	position: absolute;
	top: -1px;
	width: 18px;
	height: 18px;
}

.additionInMind__answerStatistics {
	margin-bottom: 1rem !important;
}

.additionInMind__correctAnswersList {
	display: grid;
	grid-template-columns: 1fr 2fr;
}

.additionInMind__correctAnswerLogAnswer {
	padding: 0 0.25rem;
	background-color: rgba(0, 128, 0, 0.25);
}

.additionInMind__errorList {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
}

.additionInMind__errorLogAnswer {
	padding: 0 0.25rem;
	background-color: rgba(255, 0, 0, 0.25);
}