.copyToClipboardTheWiz__copiedContainerIcon {
	position: absolute;
	margin-left: 0.5rem;
	width: 24px;
	height: 24px;
	border-radius: 0.25rem;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;
}

.copyToClipboardTheWiz__copiedContainerIcon_active {
	background-color: green;
}