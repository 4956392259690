@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap');

* {
	box-sizing: border-box;
	margin-top: 0;
	margin-bottom: 0;
}

html {
	height: 100%;
	width: 100%;
	min-width: 320px;
	font-family: 'Rubik', sans-serif;
	font-size: 16px;
	font-weight: 400;
	color: #363636;
	scroll-behavior: smooth;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
  }