.header {
	padding: 20px;
	border-bottom: 1px solid gainsboro;
}

.header__content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
}

a.header__content {
	text-decoration: none;
}

a.header__content:hover,
a.header__content:visited {
	color: #363636;
}

.header__crmLink {
	position: absolute;
	top: 0;
	right: 0;
	text-decoration: none;
	font-size: 0.8rem;
	color: silver;
	text-align: right;
}

.header__crmLink:hover,
.header__crmLink:active {
	color: grey;
}

@media screen and (max-width: 768px) {
	.header__crmLink {
		max-width: 100px;
	}
}

.header__logo {
	text-align: center;
	margin-bottom: 0.5rem;
}

.header__logo img {
	width: 50px;
	height: 50px;

}

.header__title {
	margin-bottom: 0.25rem;
	text-align: center;
	font-weight: 500;
}

.header__subtitle {
	text-align: center;
}