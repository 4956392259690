.price {
	margin-bottom: 2rem;
	width: 100%;
	max-width: 1000px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.price__content {
	margin-bottom: 3rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.price__contentHero {
	margin-bottom: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.price__contentHero > h2 {
	text-align: center;
}

.price__contentHero > div:not(:last-child) {
	margin-bottom: 2rem;
}

.price__selectors {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	margin-bottom: 1rem;
	overflow-x: auto;
	border: 1px solid gainsboro;
	border-radius: 0.25rem;
	box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
	padding: 1rem;
}

.price__selector {
	margin-bottom: 1rem;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	line-height: 2rem;
}

.price__selector:last-child {
	margin-bottom: 0;
}

.price__selector > div:first-child {
	margin-right: 0.5rem;
}

.price__selector > label:not(:last-child) {
	margin-right: 1rem;
}

.price__selector > label > input[type="checkbox"] {
	margin-right: 0.5rem;
}

.price__programData {
	width: 100%;
}

.price__programDataSections:not(:last-child) {
	margin-bottom: 2rem;
}

.price__programDataSections > h3 {
	margin-bottom: 1.5rem;
}

.price__programDataSection:not(:last-child) {
	margin-bottom: 2rem;
}

.price__programDataSection > h4 {
	margin-bottom: 1rem;
}

.price__programDataElements {
	border: 1px lightgray solid;
	border-radius: 0.25rem;
	box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
}

.price__programDataElement {
	display: grid;
	grid-template-columns: 1fr 3fr;
	grid-template-rows: 1fr;
	grid-gap: 1px;
	grid-template-areas: "dataName dataValue";
}

.price__programDataElement:not(:last-child) {
	border-bottom: 1px lightgray solid;
}

@media screen and (max-width: 768px) {
	.price__programDataElement {
		grid-template-columns: 1fr;
		grid-template-rows: auto 1fr;
		grid-template-areas:
			"dataName"
			"dataValue";
	}
}

.price__programDataElement > div {
	padding: 0.5rem 0.75rem;
	line-height: 1.75rem;
}

.price__programDataElement > div:first-child {
	grid-area: dataName;
	font-weight: 500;
	border-right: 1px lightgray solid;
	background-color: rgba(0, 0, 0, 0.05);
}

.price__programDataElement > div:last-child {
	grid-area: dataValue;
	background-color: rgba(0, 0, 0, 0.025);
}

@media screen and (max-width: 768px) {
	.price__programDataElement > div:first-child {
		border-right: none;
		border-bottom: 1px lightgray solid;
	}

	.price__programDataElement > div div:not(:last-child) {
		margin-bottom: 0.5rem;
	}
}

.price__fontMedium {
	font-weight: 500;
}

.price__copiedContainer {
	position: relative;
}

.price__divider {
	margin: 0 0 0.5rem;
	height: 0.5rem;
	width: 80%;
	padding: 0.5rem 0 0;
	border-bottom: 1px gainsboro solid;
}