.programList {
	margin-bottom: 2rem;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

.programList__selectors {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 1rem;
	overflow-x: auto;
}

.programList__selector {
	margin-bottom: 1rem;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.programList__selector > div:first-child {
	margin-right: 0.5rem;
}

.programList__tableContainer {
	width: 100%;
	overflow-x: auto;
}

.programList__listTable {
	width: 100%;
	min-width: 1300px;
	max-width: 1500px;
	margin: 0 auto;
	border-spacing: 0;
	border-collapse: collapse;
}

.programList__listTable > tr > th {
	border: 1px solid #a6a6a6;
	padding: 0.5rem 0.75rem;
	background-color: #e1efd9;
}

.programList__listTable > tr:nth-child(1) > th:nth-child(1),
.programList__listTable > tr > td:nth-child(1) {
	width: 75px;
}

.programList__listTable > tr:nth-child(1) > th:nth-child(3) {
	width: 150px;
}

.programList__listTable > tr:nth-child(1) > th:nth-child(4) {
	width: 85px;
}

.programList__listTable > tr:nth-child(1) > th:nth-child(5) {
	width: 150px;
}

.programList__listTable > tr:nth-child(2) > th:nth-child(2),
.programList__listTable > tr:nth-child(3) > th {
	width: 120px;
}

.programList__listTable > tr > td {
	border: 1px solid #a6a6a6;
	padding: 1rem 0.75rem;
	line-height: 1.5rem;
}

.programList__listTable > tr:nth-child(4) > td {
	padding: 1.5rem 0.75rem;
}

.programList__cellCourseTitle {
	background-color:#fff2cc;
}

.programList__fontMedium {
	font-weight: 500;
}

.programList__textAlignCenter {
	text-align: center;
}