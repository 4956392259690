.main {
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.main__contentHero {
	margin-bottom: 2rem;
}

.main__contentHero > h2 {
	text-align: center;
}

.main__content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.main__contentSections {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

.main__contentSection {
	border: 1px grey solid;
	border-radius: 0.5rem;
	background-color: rgba(221, 160, 221, 0.5);
	padding: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 250px;
	height: 200px;
}

.main__contentSection > h3 {
	margin-bottom: 1.5rem;
}

.main__contentSection > div {
	text-align: center;
}

.main__contentSection > div:not(:last-child) {
	margin-bottom: 1.5rem;
}